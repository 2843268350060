import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { sHtml } from '../clever-ui-kit/style'
import loadable from '@loadable/component'
import { StateContext, DispatchContext, initialState } from '../context'
import SEO from '../components/seo'

// const Test = loadable((props) => import(`${props.page}`))
const IntroPage = loadable((props) =>
  import(`../clever-ui-kit/intros/IntroPage/IntroPage`)
)

const AsyncComponent = (props) => {
  // this is nor working in SSR
  if (typeof window === 'undefined' || !window) return <></>
  const LoadableComponent = loadable((props) => import(`${props.path}`))
  return <LoadableComponent {...props} />
}

const reduceObject = (obj) =>
  Object.keys(obj)
    .filter((e) => obj[e] !== null)
    .reduce((o, e) => {
      o[e] = obj[e]
      return o
    }, {})

export default function PageTemplate({ data }) {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  // const language = state?.language ? state.language : initialState.language
  const theme = state?.theme ? state.theme : initialState.theme
  const pageData = data.thisPage
  const page = data.thisPage?.frontmatter
  const sections = data.sections?.nodes.map((node) => ({ ...node.frontmatter }))

  /*eslint-disable */
  useEffect(() => {
    dispatch({ type: 'SET_MENU', payload: false })
  }, [])

  useEffect(() => {
    dispatch({ type: 'SET_LANGUAGE', payload: page.language })
  }, [])
  /*eslint-enable */

  return (
    <>
      <SEO
        title={page?.seo_title ? page?.seo_title : page?.title}
        description={page?.seo_desc || page?.description}
        image={
          page?.image_socials?.publicURL || page?.image_background?.publicURL
        }
      />
      {
        page.title && !page.noIntro && (
        <IntroPage
          title={page.title}
          subtitle={page.subtitle}
          description={page.description}
          image={page.image_background}
          theme={theme}
        />
      )}
      {/* {page.noIntro && <div css={{ paddingTop: '4rem' }}></div>} */}
      {pageData.html && (
        <div dangerouslySetInnerHTML={{ __html: pageData.html }} css={sHtml} />
      )}
      {sections
        // .filter((s, id) => id === 0)
        .map(
          (section, sectionid) =>
            section.language === page.language &&
            section.component && (
              <AsyncComponent
                path={`./paths/_${section.component}`} // path={'./test'}
                component={section.component}
                theme={theme}
                data={reduceObject(section)}
                key={sectionid}
              />
            )
        )}
    </>
  )
}

export const query = graphql`
  query($slug: String!, $sections: [String], $language: String!) {
    thisPage: markdownRemark(fileAbsolutePath: { regex: $slug }) {
      html
      frontmatter {
        title
        subtitle
        description
        name
        url
        noIntro
        language
        seo_title
        seo_desc
        # image_socials {
        #   publicURL
        # }
        image_background {
          publicURL
          name
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    sections: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/sections/" }
        frontmatter: { name: { in: $sections }, language: { eq: $language } }
      }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      nodes {
        # html
        frontmatter {
          name
          language
          component
          slug_section
          subtitle
          description
          # body
          text
          link_external
          link_page
          link_section
          title
          titleObject {
            text
            variant
          }
          countries
          titleObjectList {
            variant
            texts {
              fontWeight
              text
              uppercase
            }
          }
          subtitleObject {
            fontWeight
            variant
            text
            uppercase
          }
          descriptionObject {
            fontWeight
            variant
            text
            uppercase
          }
          buttonGradient {
            text
            uppercase
            link_page
            link_section
          }
          button {
            text
            uppercase
            link_external
            link_page
            link_section
          }
          image {
            name
            childImageSharp {
              fluid(maxWidth: 600, quality: 100, toFormat: JPG) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          compass {
            title
            backButton
            C {
              title
              subtitle
              body
            }
            N {
              title
              subtitle
              body
              countries {
                title
                flag {
                  publicURL
                }
              }
            }
            E {
              title
              subtitle
              body
              countries {
                title
                flag {
                  publicURL
                }
              }
            }
            S {
              title
              subtitle
              body
              countries {
                title
                flag {
                  publicURL
                }
              }
            }
            W {
              title
              subtitle
              body
              countries {
                title
                flag {
                  publicURL
                }
              }
            }
          }
          files {
            title
            image {
              extension
              publicURL
              name
              childImageSharp {
                fluid(
                  maxWidth: 400
                  quality: 100
                  toFormat: JPG
                  jpegProgressive: true
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            file
            file_url
          }
          tabs {
            links {
              text
              link
            }
            color
            titles {
              smaller
              text
            }
            descs {
              text
            }
            images {
              image {
                name
                childImageSharp {
                  fluid(maxWidth: 600, quality: 100, toFormat: JPG) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          comments {
            text
            author_title
            author_subtitle
            author_link
            # author_image {
            #   childImageSharp {
            #     fluid(maxWidth: 64, quality: 100, toFormat: JPG) {
            #       ...GatsbyImageSharpFluid
            #     }
            #   }
            # }
          }
          features {
            titleObject {
              text
              variant
            }
            description
            props {
              title
            }
            # image {
            #   childImageSharp {
            #     fluid(maxWidth: 300, quality: 100, toFormat: JPG) {
            #       ...GatsbyImageSharpFluid
            #     }
            #   }
            # }
            buttonSmall {
              text_before
              text
              uppercase
              link_page
              link_section
            }
            slug_section
          }
          stats {
            value
            unit
            title
            description
            image {
              name
              publicURL
            }
          }
          block {
            title
            text
          }
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100, toFormat: JPG) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          form_inputs {
            unloading_place_date {
              label
              help
              placeholder
              isRequired
            }
            loading_place {
              label
              placeholder
              isRequired
              help
            }
            message {
              label
              placeholder
              help
              isRequired
            }
            loading_place_date {
              label
              help
              placeholder
              isRequired
            }
            unloading_place {
              label
              isRequired
              help
              placeholder
            }
            width {
              label
              isRequired
              help
              placeholder
            }
            height {
              label
              isRequired
              help
              placeholder
            }
            depth {
              label
              isRequired
              help
              placeholder
            }
            weight {
              label
              isRequired
              help
              placeholder
            }
            phone {
              label
              help
              placeholder
              isRequired
            }
            loading_place_extra {
              label
              help
              placeholder
              isRequired
            }
            unloading_place_extra {
              label
              help
              placeholder
              isRequired
            }
            email {
              label
              help
              placeholder
              isRequired
            }
            nip {
              label
              help
              placeholder
              isRequired
            }
          }

          form_agreements {
            text
          }
          form_button {
            send
            fill
            more
            less
          }
          form_success {
            title
            text
          }
          form_failure {
            title
            text
          }
          form_config {
            fromname
            fromemail
            replyto
            cc
            bcc
            messagetype
            toemail
          }
          aboutHero_image1 {
            childImageSharp {
              fluid(maxWidth: 480, quality: 100, toFormat: JPG) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          aboutHero_image2 {
            childImageSharp {
              fluid(maxWidth: 480, quality: 100, toFormat: JPG) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          aboutStats_items {
            value
            desc
            icon {
              publicURL
            }
          }
          about_sections {
            title
            subtitle
            description
            body
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100, toFormat: JPG) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          items {
            title
            subtitle
            description
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100, toFormat: JPG) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link {
              text
              uppercase
              link_external
              link_page
              link_section
            }
          }
          valuation_containers {
            title
            file {
              publicURL
            }
          }
          valuation_palletes {
            title
            file {
              publicURL
            }
          }
          icons {
            title
            file {
              publicURL
            }
          }
          toEmail
          contact_offices {
            title
            subtitle
            address
            phone
            email
            # spec
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100, toFormat: JPG) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
